import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { OverviewArray } from ".."
import useAvatar from "../../../../hooks/useAvatar"
import { OverviewDataFragment } from "../../../../__generated__/graphql-gatsby"
import Divider from "../divider"

interface IProps {
  data: OverviewArray
}

interface CardProps {
  item: Maybe<OverviewDataFragment>
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 372px;
  margin: ${({ theme }) => theme.spacing(4)}px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows[5]};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    margin: ${({ theme }) => theme.spacing(4)}px 0;
  }
`

const Img = styled(GatsbyImage)`
  aspect-ratio: 1;
`

const Text = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing(5)}px ${theme.spacing(2)}px ${theme.spacing(1.5)}px`};
  height: 202px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: ${({ theme }) => theme.palette.background.paper};
  filter: drop-shadow(0 -8px 32px rgba(28, 26, 32, 0.65));

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.md}px) {
    height: fit-content;
  }
  &::before {
    content: "";
    background-color: ${({ theme }) => theme.palette.background.paper};
    height: 88px;
    position: absolute;
    left: 0;
    right: 0;
    top: -87px;
    clip-path: polygon(0 56%, 0% 100%, 100% 100%);
  }

  .description {
    max-height: 112px;
    overflow: hidden;
  }
`

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(-5)}px;

  .name {
    margin-bottom: -4px;
  }
`

const Contact = styled.div`
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > * + * {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }
`

const Card: React.FC<CardProps> = ({ item }) => {
  if (item?.__typename !== "WpVrijwilliger") return null

  const avatar = useAvatar()

  const vrijwilliger = item.vrijwilliger?.vrijwilliger
  const imgData = vrijwilliger?.afbeelding
  const img = imgData?.localFile?.childImageSharp?.gatsbyImageData
  const { weergaveNaam, omschrijving, eMail, telefoon } = {
    ...vrijwilliger?.info,
  }

  return (
    <Wrapper>
      <Img image={img ? img : avatar} alt={String(imgData?.altText)} />
      <Text>
        <Heading>
          <Typography
            color="primary"
            className="name"
            variant="h5"
            component="span"
          >
            {weergaveNaam}
          </Typography>
          <Typography color="secondary" variant="caption" component="span">
            {omschrijving?.toLocaleUpperCase()}
          </Typography>
        </Heading>
        {item.vrijwilliger?.omschrijving && (
          <Typography
            variant="caption"
            component="div"
            className="description"
            dangerouslySetInnerHTML={{
              __html: item.vrijwilliger.omschrijving,
            }}
          />
        )}

        <Contact>
          {eMail && (
            <>
              <Link color="secondary" href={`mailto:${eMail}`}>
                {eMail}
              </Link>
              {telefoon && (
                <Typography color="primary" component="span">
                  {" "}
                  |{" "}
                </Typography>
              )}
            </>
          )}

          {telefoon && <Link color="secondary">{telefoon}</Link>}

          {!eMail && !telefoon && <Divider />}
        </Contact>
      </Text>
    </Wrapper>
  )
}

const Medium: React.FC<IProps> = ({ data }) => (
  <>
    {data.map(item => (
      <Card key={item?.id} item={item} />
    ))}
  </>
)

export default Medium
