import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

const Spacer = styled(Typography)`
  font-weight: 500;
  font-size: 1.18rem;
  margin: ${({ theme }) =>
    `${theme.spacing(-1)}px 0 ${theme.spacing(-0.75)}px`};
  letter-spacing: -3px;
  color: ${({ theme }) => theme.palette.secondary.light};
`

const Divider: React.FC = () => (
  <Spacer variant="body1" align="center" children="――――――" />
)

export default Divider
