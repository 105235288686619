import { graphql, useStaticQuery } from "gatsby"
import { AvatarQueryQuery } from "../__generated__/graphql-gatsby"

const useAvatar = () => {
  const data = useStaticQuery<AvatarQueryQuery>(graphql`
    fragment AvatarData on File {
      childImageSharp {
        gatsbyImageData(
          transformOptions: { fit: CONTAIN }
          placeholder: TRACED_SVG
        )
      }
    }
    query AvatarQuery {
      orange: file(relativePath: { eq: "avatar.png" }) {
        ...AvatarData
      }
      purple: file(relativePath: { eq: "avatar2.png" }) {
        ...AvatarData
      }
    }
  `)
  const random = Math.random()

  if (random < 0.25) {
    return data.purple?.childImageSharp?.gatsbyImageData
  } else {
    return data.orange?.childImageSharp?.gatsbyImageData
  }
}

export default useAvatar
